import React from 'react';
import PropTypes from 'prop-types';

function List({ id, children }) {
  return (
    <div id={id} data-testid={id} className="list">
      { children }
    </div>
  );
}

List.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
};

List.defaultProps = {
  id: undefined,
  children: undefined,
};

export default List;
