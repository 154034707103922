import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  useConfiguration,
  withConfiguration,
} from '../context/ConfigurationContext';

import Unknown from '../components/Errors/Unknown';
import { useFlipperQuery } from '../hooks/useFlipperQuery';

const withFlipper = (ChildComponent) => {
  const WrappedComponent = withConfiguration((properties) => {
    const { innerRef, testFlipper, ...props } = properties;

    const configuration = useConfiguration();
    const { data, isError, isLoading } = useFlipperQuery({
      enabled: !testFlipper && !configuration.testFlipper,
    });
    const flipper = testFlipper ?? configuration.testFlipper ?? data;

    if (flipper) {
      return <ChildComponent {...props} flipper={flipper} ref={innerRef} />;
    }
    if (isError) {
      return <Unknown {...props} />;
    }
    /* istanbul ignore else */
    if (isLoading) {
      return null;
    }
    /* istanbul ignore next */
    return null;
  });

  WrappedComponent.propTypes = {
    testFlipper: PropTypes.shape({}),
  };
  const ForwardRefWrappedComponent = forwardRef((props, ref) => (
    <WrappedComponent {...props} innerRef={ref} />
  ));
  ForwardRefWrappedComponent.displayName = ChildComponent.displayName || ChildComponent.name;
  return ForwardRefWrappedComponent;
};
export default withFlipper;
