import React from 'react';
import { Formik } from 'formik';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import {
  isEmpty,
  SubmitButton,
  TextInput,
  intlShape,
  Form,
  ActionBar,
  Row,
  Column, ButtonGroup,
  apiShape, withSession, sessionShape, notifierShape, withNotifier, withApi, withBackend, backendShape,
} from 'lcm-iot-commons';

import PasswordInput from '../Form/PasswordInput';
import withZxcvbn, { zxcvbnShape } from '../../wrappers/withZxcvbn';

export function UserPasswordEdit({
  intl, api, notifier, zxcvbn, session, backend, userDetails,
}) {
  const handleOnSubmit = async (values, actions) => {
    try {
      const updateObject = {
        current_password: values.password,
        new_password: values.newPassword,
      };
      await api.patch('/users/current/password', updateObject);
      notifier.showSuccess(intl.formatMessage({ id: 'edit_password.success_notification' }));
      await backend.delete('/invalidate_current_user_sessions');
      session.deleteSession();
    } catch (error) {
      if (error.contains('invalid_password')) {
        notifier.showError(intl.formatMessage({ id: 'api.error.invalid_password' }));
      } else {
        notifier.showError(api.translateError(error));
      }
    } finally {
      actions.setSubmitting(false);
      actions.resetForm({});
    }
  };

  const validateForm = (values) => {
    const errors = {};
    if (isEmpty(values.password)) {
      errors.password = intl.formatMessage({ id: 'validation.password.mandatory' });
    }
    if (isEmpty(values.newPassword)) {
      errors.newPassword = intl.formatMessage({ id: 'validation.password.mandatory' });
    } else {
      const userInputs = [userDetails.email, userDetails.first_name, userDetails.last_name];
      const passwordError = zxcvbn.checkPassword(values.newPassword, userInputs).error;
      if (passwordError) {
        errors.newPassword = passwordError;
      }
    }
    return errors;
  };

  const renderForm = (formProps) => {
    const { isSubmitting } = formProps;
    return (
      <Form {...formProps}>
        <Row id="user-profile-password">
          <Column md={8}>
            <TextInput
              {...formProps}
              id="user-profile-current-password"
              component={TextInput}
              label={intl.formatMessage({ id: 'label.current_password' })}
              intl={intl}
              name="password"
              disabled={isSubmitting}
              type="password"
              required
            />
          </Column>
        </Row>
        <Row id="user-profile-new-password">
          <Column md={8}>
            <PasswordInput
              {...formProps}
              label={intl.formatMessage({ id: 'label.new_password' })}
              intl={intl}
              name="newPassword"
              type="password"
              disabled={isSubmitting}
              required
              userDetails={userDetails}
            />
          </Column>
        </Row>
        <div data-testid="user-profile-note">Note: All existing sessions will be terminated after password change</div>
        <ButtonGroup className="btn-group">
          <SubmitButton
            id="edit-password-submit"
            fetching={isSubmitting}
            disabled={!formProps.dirty}
            text={intl.formatMessage({ id: 'change_current_password.submit' })}
          />
        </ButtonGroup>
      </Form>
    );
  };

  return (
    <div className="form-group">
      <ActionBar>
        <h2 id="change-current-password-header">{intl.formatMessage({ id: 'change_current_password.header' })}</h2>
      </ActionBar>
      <Formik
        id="edit-password-form"
        onSubmit={handleOnSubmit}
        validate={validateForm}
        render={renderForm}
      />
    </div>
  );
}

UserPasswordEdit.propTypes = {
  intl: intlShape.isRequired,
  api: apiShape.isRequired,
  notifier: notifierShape.isRequired,
  zxcvbn: zxcvbnShape.isRequired,
  session: sessionShape.isRequired,
  backend: backendShape.isRequired,
  userDetails: PropTypes.shape({
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
};

UserPasswordEdit.defaultProps = {
  userDetails: {},
};

export default injectIntl(withZxcvbn(withSession(withBackend(withNotifier(withApi(UserPasswordEdit))))));
