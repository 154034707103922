import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { htmlFormat, htmlLink, url } from '../../utils';
import Icon from '../Icon';
import Heading from '../Heading';
import CONFIGURATION from '../../configuration';
import { withUser } from '../../context';
import { userShape } from '../../shapes';

export function AllObjectsNoAssetsFoundClue({ nodeId, user }) {
  const intl = useIntl();

  return (
    <div id="no-assets-found" className="no-assets-found-clue">
      <Icon name="icon3-eh-products" />
      <Heading title={intl.formatMessage({ id: 'assets.no_assets_found' })} level={2} />
      {!user.demoDataActive && (
        <p>
          <FormattedMessage
            id="asset.nothing_found.all_objects.message.demo_data"
            values={{
              ...htmlFormat,
              a: htmlLink({ href: CONFIGURATION.HELP_URL_DEMODATA, target: '_blank' }),
            }}
          />
        </p>
      )}
      <p><FormattedMessage id="asset.nothing_found.all_objects.message.auto_registration" /></p>
      <div className="no-assets-found-links">
        <Link className="btn btn-primary" to={url(nodeId ? `/nodes/${nodeId}/assets/create` : '/assets/create')}>
          <FormattedMessage id="button.create_asset" />
        </Link>
        <Link className="btn" to={url('/subscription')}>
          <FormattedMessage id="auto_registration.label.is_inactive" />
        </Link>
      </div>
    </div>
  );
}

AllObjectsNoAssetsFoundClue.propTypes = {
  nodeId: PropTypes.number,
  user: userShape.isRequired,
};

export default withUser(AllObjectsNoAssetsFoundClue);
