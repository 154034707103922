import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { MenuItem } from 'react-bootstrap';
import ConfirmationModal from 'lcm-iot-commons/client/lib/components/ConfirmationModal';
import {
  backendShape,
  ItemMenu,
  ListItem,
  useNotifier,
  withBackend,
  Icon,
} from 'lcm-iot-commons';
import OpcUaServerCertificateStatus from '../../constants/OpcUaServerCertificateStatus';
import { opcUaServerCertificateIconClassName, opcUaServerCertificateStatusClassName } from '../../utils/statusUtils';
import { opcUaServerCertificateShape } from '../../shapes/opcUaServerCertificatesShape';
import { accessRightsShape } from '../../shapes/accessRightsShape';
import { opcUaServerShape } from '../../shapes/opcUaServersShape';

export function OpcUaServerCertificateItem({
  onCertificateRemoved,
  onCertificateStatusChanged,
  opcUaServer,
  certificate,
  accessRights,
  backend,
  type,
}) {
  const intl = useIntl();
  const notifier = useNotifier();

  const [deleting, setDeleting] = useState(false);

  const onCloseModal = () => setDeleting(false);

  const onDeleteConfirmationModal = async () => {
    setDeleting(false);
    try {
      await backend.delete(`/api_subscriptions/${opcUaServer.subscription_id}/opc_ua_servers/${opcUaServer.id}/${type}_certificates/${certificate.id}`);
      onCertificateRemoved(certificate);
      notifier.showSuccess(intl.formatMessage({ id: `${type}_certificate.delete.notification` }));
    } catch (error) {
      notifier.showError(backend.translateError(error));
    }
  };

  const handleOnTrustOrRejectClick = async () => {
    const newStatus = certificate.status === 'trusted' ? 'rejected' : 'trusted';
    try {
      await backend.patch(`/api_subscriptions/${opcUaServer.subscription_id}/opc_ua_servers/${opcUaServer.id}/client_certificates/${certificate.id}`, { status: newStatus });
      onCertificateStatusChanged();
      notifier.showSuccess(intl.formatMessage({ id: `client_certificate.${newStatus}.notification` }));
    } catch (error) {
      notifier.showError(backend.translateError(error));
    }
  };

  const handleOnDeleteClick = () => setDeleting(true);

  const deleteConfirmationModal = deleting && (
    <ConfirmationModal
      id="delete-modal"
      intl={intl}
      show
      titleText={intl.formatMessage({ id: `${type}_certificate_delete.actions.modal_title` })}
      messageText={intl.formatMessage({ id: `${type}_certificate_delete.actions.modal_message` }, { certificate: certificate.file_name })}
      onConfirm={onDeleteConfirmationModal}
      onClose={onCloseModal}
    />
  );

  const description = type === 'client' && (
    <div className="opc-ua-description">
      <Icon name={opcUaServerCertificateStatusClassName(certificate.status)} />
      <span><FormattedMessage id={`enum.opc_ua_server.certificate.${certificate.status}`} /></span>
    </div>
  );

  const actions = (
    <ItemMenu>
      {type === 'client' && (
        <>
          <MenuItem
            id="item-trust"
            onSelect={handleOnTrustOrRejectClick}
            disabled={!accessRights.canUpdate || certificate.status === OpcUaServerCertificateStatus.TRUSTED}
          >
            <FormattedMessage id="button.trust" />
          </MenuItem>
          <MenuItem
            id="item-reject"
            onSelect={handleOnTrustOrRejectClick}
            disabled={!accessRights.canUpdate || certificate.status === OpcUaServerCertificateStatus.REJECTED}
          >
            <FormattedMessage id="button.reject" />
          </MenuItem>
        </>
      )}
      <MenuItem id="item-delete" onSelect={handleOnDeleteClick} disabled={!accessRights.canUpdate}><FormattedMessage id="button.delete" /></MenuItem>
    </ItemMenu>
  );

  return (
    <>
      <ListItem
        id={`item-${certificate.id}`}
        icon={opcUaServerCertificateIconClassName()}
        title={certificate.file_name}
        description={description}
        actions={actions}
      />
      {deleteConfirmationModal}
    </>
  );
}

OpcUaServerCertificateItem.propTypes = {
  onCertificateRemoved: PropTypes.func,
  onCertificateStatusChanged: PropTypes.func,
  opcUaServer: opcUaServerShape.isRequired,
  certificate: opcUaServerCertificateShape.isRequired,
  backend: backendShape.isRequired,
  accessRights: accessRightsShape.isRequired,
  type: PropTypes.oneOf(['ca', 'client']),
};

OpcUaServerCertificateItem.defaultProps = {
  onCertificateRemoved: undefined,
  onCertificateStatusChanged: undefined,
  type: 'client',
};

export default withBackend(OpcUaServerCertificateItem);
