/* eslint-disable import/no-cycle */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { MenuItem } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ConflictError, BadRequestError } from '../../api';
import {
  withNotifier,
  notifierShape,
  withApi,
  apiShape,
  withRules,
  rulesShape,
  withBrowser,
  browserShape,
} from '../../context';
import { extractAccessRights } from '../../extractors/accessRightsExtractor';
import AllObjectsSelectModal from '../AllObjects/AllObjectsSelectModal';
import ConfirmationModal from '../ConfirmationModal';
import ItemMenu from '../ItemMenu';
import ListItem from '../List/ListItem';

import {
  accessRightsShape,
  assetShape,
  instrumentationShape,
  nodeShape,
  intlShape,
} from '../../shapes';

import {
  isNotEmpty,
  isSomething,
  url,
} from '../../utils';
import SubscriptionAssignmentMenuItem from '../Subscriptions/SubscriptionAssigmentMenuItem';
import DocumentExportModal from '../Documents/DocumentExportModal';
import { getSpecificationChips } from '../../utils/chipsUtils';

export class NodeItem extends Component {
  static propTypes = {
    node: nodeShape.isRequired,
    showItemMenu: PropTypes.bool,
    intl: intlShape,
    onNodeRemoved: PropTypes.func,
    parentInstrumentation: instrumentationShape,
    parentAsset: assetShape,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    fromDetailsPage: PropTypes.bool,
    assignedObjectAccessRights: accessRightsShape,
    rules: rulesShape,
    hideStatus: PropTypes.bool,
    api: apiShape,
    notifier: notifierShape,
    browser: browserShape,
    options: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    showItemMenu: false,
    disabled: false,
    fromDetailsPage: false,
    hideStatus: false,
  };

  constructor(props) {
    super(props);
    /* istanbul ignore next */

    this.handleOnItemMenuOpen = this.handleOnItemMenuOpen.bind(this);
    this.handleOnDashboardClick = this.handleOnDashboardClick.bind(this);
    this.handleOnEditClick = this.handleOnEditClick.bind(this);
    this.handleOnDetailsClick = this.handleOnDetailsClick.bind(this);
    this.handleOnMoveClick = this.handleOnMoveClick.bind(this);
    this.handleOnModalClose = this.handleOnModalClose.bind(this);
    this.handleOnNodeSelect = this.handleOnNodeSelect.bind(this);
    this.handleOnDeleteClick = this.handleOnDeleteClick.bind(this);
    this.handleOnUnassignClick = this.handleOnUnassignClick.bind(this);
    this.handleOnConfirm = this.handleOnConfirm.bind(this);

    this.state = {
      accessRights: {
        canUpdate: false,
        canDelete: false,
        canPermit: false,
        isLoading: true,
      },
      moving: false,
      deleting: false,
      unassigning: false,
    };
  }

  async handleOnItemMenuOpen() {
    const {
      node, api, notifier,
    } = this.props;
    try {
      const accessRights = extractAccessRights('Node', node.id, await api.get('/users/current/access_rights', {
        permitable_type: 'Node',
        permitable_id: node.id,
      }));

      this.setState({ accessRights: { ...accessRights, isLoading: false } });
    } catch (error) {
      notifier.showError(api.translateError(error));
    }
  }

  handleOnEditClick() {
    const { node, rules, browser } = this.props;
    browser.navigateTo(rules.node(node).get('editPath'));
  }

  handleOnDetailsClick() {
    const { node, browser } = this.props;
    browser.navigateTo(`/nodes/${node.id}/details`);
  }

  handleOnDashboardClick() {
    const { node, browser } = this.props;
    browser.navigateTo(`/overview/nodes/${node.id}`);
  }

  async handleOnConfirm() {
    const {
      intl, onNodeRemoved, node, parentInstrumentation, parentAsset, rules, api, notifier,
    } = this.props;
    const { deleting, unassigning } = this.state;

    const nodeRules = rules.node(node);

    this.resetActionsState();
    if (deleting) {
      try {
        await api.delete(`/nodes/${node.id}`);
        notifier.showSuccess(intl.formatMessage({ id: nodeRules.get('deleteNotificationKey') }));
        onNodeRemoved(node);
      } catch (error) {
        if (error instanceof ConflictError) {
          notifier.showError(intl.formatMessage({ id: nodeRules.get('deleteAssignedRestrictionNotificationKey') }));
        } else {
          notifier.showError(api.translateError(error));
        }
      }
    } else if (unassigning) {
      try {
        if (parentInstrumentation) {
          await api.delete(`/instrumentations/${parentInstrumentation.id}/nodes`, { nodes: [{ id: node.id }] });
          notifier.showSuccess(intl.formatMessage({ id: nodeRules.get('unassignNotificationKey') }));
          onNodeRemoved(node);
        }
        if (parentAsset) {
          await api.delete(`/assets/${parentAsset.id}/nodes`, { nodes: [{ id: node.id }] });
          notifier.showSuccess(intl.formatMessage({ id: nodeRules.get('unassignNotificationKey') }));
          onNodeRemoved(node);
        }
      } catch (error) {
        notifier.showError(api.translateError(error));
      }
    }
  }

  handleOnMoveClick() {
    this.setState({ moving: true });
  }

  handleOnDeleteClick() {
    this.setState({ deleting: true });
  }

  handleOnModalClose() {
    this.resetActionsState();
  }

  handleOnUnassignClick() {
    this.setState({ unassigning: true });
  }

  async handleOnNodeSelect(parentNode) {
    const {
      intl, node, onNodeRemoved, fromDetailsPage, rules, api, notifier,
    } = this.props;
    const data = parentNode ? { parent: { id: parentNode.id } } : { parent: null };
    const nodeRules = rules.node(node);

    try {
      await api.patch(`/nodes/${node.id}`, data);
      notifier.showSuccess(intl.formatMessage({ id: nodeRules.get('moveNotificationKey') }));

      if (!fromDetailsPage) {
        onNodeRemoved(node);
      } else {
        this.resetActionsState();
      }
    } catch (error) {
      if (error instanceof BadRequestError) {
        notifier.showError(intl.formatMessage({ id: nodeRules.get('moveInsufficientPermissionsNotificationKey') }));
      } else {
        notifier.showError(api.translateError(error));
      }
    }
  }

  formatDetails(node) {
    let details = node.typeName ? node.typeName : '';

    if (isNotEmpty(details) && isNotEmpty(node.description)) {
      details = details.concat(', ');
    }

    if (isNotEmpty(node.description)) {
      details = details.concat(node.description);
    }

    return details;
  }

  resetActionsState() {
    this.setState({ moving: false, deleting: false, unassigning: false });
  }

  renderItemMenu() {
    const {
      rules, fromDetailsPage, assignedObjectAccessRights, node,
    } = this.props;
    const { canUpdate, canDelete, canPermit } = this.state.accessRights;
    const applicationRules = rules.application();
    const nodeRules = rules.node(node);
    const disableEditButton = !canUpdate || !nodeRules.get('editable');
    return (
      <ItemMenu isLoading={this.state.accessRights.isLoading} onOpen={this.handleOnItemMenuOpen}>
        <MenuItem id="item-details" onSelect={this.handleOnDetailsClick}><FormattedMessage id="global.show_details" /></MenuItem>
        <MenuItem id="item-edit" onSelect={this.handleOnEditClick} disabled={disableEditButton}><FormattedMessage id="button.edit" /></MenuItem>
        { !fromDetailsPage ? <MenuItem id="item-move" onSelect={this.handleOnMoveClick} disabled={!canPermit}><FormattedMessage id="button.move" /></MenuItem> : null }
        { fromDetailsPage ? (
          <MenuItem id="item-unassign" onSelect={this.handleOnUnassignClick} disabled={!assignedObjectAccessRights.canPermit}>
            <FormattedMessage id="button.unassign" />
          </MenuItem>
        ) : null }
        { applicationRules.get('showFilesExportInContextMenu') && (
        <DocumentExportModal object={{ node }}>
          {({ showModal }) => (
            <MenuItem onSelect={showModal}><FormattedMessage id="button.export_files" /></MenuItem>
          )}
        </DocumentExportModal>
        )}
        <MenuItem id="item-delete" onSelect={this.handleOnDeleteClick} disabled={!canDelete}><FormattedMessage id="button.delete" /></MenuItem>
        {rules.application().get('showDashboardInNodeItemMenu') && <MenuItem id="item-dashboard" onSelect={this.handleOnDashboardClick}><FormattedMessage id="title.dashboard" /></MenuItem>}
        { !fromDetailsPage ? (
          <>
            <SubscriptionAssignmentMenuItem id={node.id} type="node" assign />
            <SubscriptionAssignmentMenuItem id={node.id} type="node" assign={false} />
          </>
        ) : null }
      </ItemMenu>
    );
  }

  renderConfirmationModal() {
    const { intl, rules, node } = this.props;
    const { moving, deleting, unassigning } = this.state;
    const nodeRules = rules.node(node);
    return (
      <>
        {moving ? (
          <AllObjectsSelectModal
            onClose={this.handleOnModalClose}
            onSelectNode={this.handleOnNodeSelect}
            currentNode={node}
            canSelectRootNode={isSomething(node.parentId)}
            disabledNodeIds={[node.id]}
          />
        ) : null}
        {deleting || unassigning ? (
          <ConfirmationModal
            id="confirmation-modal"
            show
            titleText={intl.formatMessage({ id: deleting ? nodeRules.get('deleteModalTitleKey') : nodeRules.get('unassignModalTitleKey') })}
            messageText={intl.formatMessage({ id: deleting ? nodeRules.get('deleteModalMessageKey') : nodeRules.get('unassignModalMessageKey') })}
            onConfirm={this.handleOnConfirm}
            onClose={this.handleOnModalClose}
          />
        ) : null }
      </>
    );
  }

  renderActions() {
    const { showItemMenu } = this.props;
    const { moving, deleting, unassigning } = this.state;
    return (
      <>
        {showItemMenu ? this.renderItemMenu() : null}
        {moving || deleting || unassigning ? this.renderConfirmationModal() : null}
      </>
    );
  }

  render() {
    const {
      node, intl, disabled, onClick, rules, hideStatus, options,
    } = this.props;
    const nodeRules = rules.node(node);
    const status = !hideStatus && node.worstStatusCode ? node.worstStatusCode : 'hidden';
    const specifications = options?.includes('specifications') ? getSpecificationChips(node.specifications, intl) : undefined;

    return disabled ? (
      <ListItem
        id={`node-item-${node.id}`}
        icon={nodeRules.get('icon')}
        title={node.name}
        description={this.formatDetails(node)}
        status={status}
        disabled
      />
    ) : (
      <ListItem
        id={`node-item-${node.id}`}
        icon={nodeRules.get('icon')}
        status={status}
        title={node.name}
        description={this.formatDetails(node)}
        iconPrimary={nodeRules.get('iconPrimary')}
        onClick={onClick ? () => onClick(node) : null}
        target={onClick ? undefined : url(nodeRules.get('detailsPath'))}
        specifications={specifications}
        actions={this.renderActions()}
      />
    );
  }
}

export default injectIntl(withBrowser(withRules(withApi(withNotifier(NodeItem)))));
