import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom-v5-compat';
import { searchResultShape } from '../../shapes';
import Picture from '../Pictures/Picture';
import { url } from '../../utils';
import { trackingShape, withTracking } from '../../context';
import Chips from '../Chips/Chips';
import CustomOverlayTrigger from '../CustomOverlayTrigger';
import Icon from '../Icon';
import { getAssignedNodesChips, getSpecificationChips } from '../../utils/chipsUtils';

export const itemIcon = ({ type = '' }) => ({
  Asset: 'icon icon-eh-device',
  Node: 'icon-primary icon-eh-folder',
  Instrumentation: 'icon lcm-iot-icon-instrumentation',
})[type] || 'icon icon-eh-folder';

export function SearchResultItem({
  disabled, item, tracking, onClick, searchOrigin, showSpecifications = false, showAssignedNodes = false, index = 0,
}) {
  const intl = useIntl();
  const navigate = useNavigate();

  const handleOnClick = () => {
    const path = item.type.toLowerCase();
    tracking?.trackEvent('Search-Result-selected', {
      itemType: item.type, index, isDisabled: disabled, searchOrigin,
    });
    navigate(url(`/${path}s/${item.id}`));
  };

  const isSpecificationChipsVisible = showSpecifications && ['Asset', 'Node', 'Instrumentation'].includes(item.type) && !disabled;
  const isAssignedNodesChipsVisible = showAssignedNodes && ['Asset', 'Instrumentation'].includes(item.type) && !disabled;

  const specificationChips = getSpecificationChips(item.specifications, intl);
  const assignedNodesChips = getAssignedNodesChips(item.nodes, intl);

  const SearchResultsItem = (
    <li
      id={`search-result-item-${item.id}`}
      className={`list-item${disabled ? ' disabled' : ''} clickable node-item`}
    >
      {/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */}
      <div className="list-item-image" role="link" onClick={handleOnClick}>
        <Picture width={50} height={50} src={item.pictureUrl} placeholder={itemIcon(item)} />
      </div>
      <div
        className="list-item-content"
        role="link"
        onClick={() => {
          handleOnClick();
          onClick?.();
        }}
      >
        <div className="list-item-header">{item.title}</div>
        <div className="list-item-details">{item.description}</div>
        {isSpecificationChipsVisible && (
        <div className="list-item-chip">
          <Icon name="icon3-eh-list chip-icon" />
          <Chips items={specificationChips} withTooltip />
        </div>
        )}
        {isAssignedNodesChipsVisible && (
        <div className="list-item-chip">
          <Icon name="icon3-eh-folder chip-icon" />
          <Chips items={assignedNodesChips} />
        </div>
        )}
      </div>
    </li>
  );

  const displayTooltip = (
    <Tooltip id="tooltip">
      <FormattedMessage id="subscription.asset_inactive_because_not_assigned_message" />
    </Tooltip>
  );

  return disabled
    ? (
      <CustomOverlayTrigger placement="bottom" overlay={displayTooltip}>
        <span>
          {SearchResultsItem}
        </span>
      </CustomOverlayTrigger>
    ) : SearchResultsItem;
}

SearchResultItem.propTypes = {
  item: searchResultShape.isRequired,
  index: PropTypes.number,
  disabled: PropTypes.bool,
  tracking: trackingShape,
  onClick: PropTypes.func,
  searchOrigin: PropTypes.string,
  showSpecifications: PropTypes.bool,
  showAssignedNodes: PropTypes.bool,
};

export default withTracking(SearchResultItem);
