import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import {
  withApi,
  withBrowser,
  withNotifier,
  withUser,
  withRules,
  withSubscription,
  withBackend,
  backendShape,
  rulesShape,
  apiShape,
  browserShape,
  notifierShape,
  subscriptionShape,
} from '../../context';
import Heading from '../Heading';
import {
  ActionBar, ActionBarButtons, ActionButton,
} from '../ActionBar';

import AssetForm from './AssetForm';
import { Container, Row, Column } from '../Grid';
import { intlShape, userShape } from '../../shapes';
import { BadRequestError, PaymentRequiredError } from '../../api';
import {
  createEnforcedTenant, isNotEmpty, isSomething, convertLabelToSpecificationKey,
} from '../../utils';
import ProductRules from '../../rules/ProductRules';

import AssetPermissionModal from './AssetPermissionModal';
import ConnectedAssetSubscriptionClue from './ConnectedAssetSubscriptionClue';
import SpecificationKeys from '../../constants/SpecificationKeys';
import * as gpsUtils from '../../utils/gpsUtils';
import BackButton from '../BackButton';

export function AssetCreate({
  intl, api, notifier, browser, user, rules, match, backend, subscription,
}) {
  const [formValues, setFormValues] = useState();
  const [subscriptionValid, setSubscriptionValid] = useState(false);
  const [askPermission, setAskPermission] = useState(false);
  const [askPermissionSerialNumber, setAskPermissionSerialNumber] = useState(null);
  const [askPermissionManufacturer, setAskPermissionManufacturer] = useState(null);
  const [quotaExceeded, setQuotaExceeded] = useState(false);
  const initialValues = {
    serial_number: match?.params?.serialNumber,
  };

  const createNewTenant = async (tenant, manufacturer) => {
    let newTenant = null;
    // New manufacturer, user has no tenant yet
    if (!tenant && manufacturer.new) {
      newTenant = { id: (await createEnforcedTenant(api, [user.firstName, user.lastName].join(' ').trim())).id };
      // Existing private manufacturer
    } else if (!manufacturer.new && !manufacturer.tenantPublic) {
      newTenant = { id: manufacturer.tenantId };
      // Create completely new tenant
    } else if (tenant?.new) {
      newTenant = { id: (await api.post('/tenants', { name: tenant.name })).id };
    }
    if (newTenant) {
      return newTenant;
    }
    return tenant;
  };

  const createNewProduct = async (product, manufacturer, tenant, values) => {
    let newProduct;
    if (product.new) {
      newProduct = await api.post('/products', {
        manufacturer: { id: manufacturer.id },
        name: product.name,
        product_code: product.code,
        tenant: { id: tenant.id },
      });

      // assign category to new product if needed
      if (values.product_category) {
        await api.post(`/products/${newProduct.id}/categories`, {
          categories: [
            {
              id: values.product_category.id,
            },
          ],
        });
      }
    }
    if (newProduct) {
      return newProduct;
    }
    return product;
  };

  const handleError = async (error, actions, values) => {
    if (error instanceof BadRequestError
      && error.contains('not_unique_in_scope', 'serial_number')) {
      actions.setErrors({
        serial_number: intl.formatMessage({ id: 'api.error.serial_number.not_unique_in_scope' }),
      });
      const response = await api.get(`/assets?serial_number=${values.serial_number}&manufacturer_id=${values.manufacturer.id}`);
      if (response.assets.length === 0) {
        setAskPermission(true);
        setAskPermissionSerialNumber(values.serial_number);
        setAskPermissionManufacturer(values.manufacturer);
      }
    } else if (
      error instanceof PaymentRequiredError && error.contains('quota_exceeded')
    ) {
      setQuotaExceeded(true);
    } else {
      notifier.showError(api.translateError(error));
    }
  };

  const getUrlToNavigate = (proceedWithTag, needsInstrumentation, response) => {
    let url = '';
    let endpoint = '';
    if (proceedWithTag || needsInstrumentation) {
      if (needsInstrumentation) {
        endpoint = '/instrumentations/create';
      } else {
        endpoint = '/instrumentations/assign';
      }
      if (match.params.id) {
        url = `/nodes/${match.params.id}/assets/${response.id}${endpoint}`;
      } else {
        url = `/assets/${response.id}${endpoint}`;
      }
    } else if (match.params?.id) {
      url = `/nodes/${match.params.id}`;
    } else {
      url = `/assets/${response.id}`;
    }
    return url;
  };

  const setSpecifications = (productRules, values, manufacturer) => {
    const showMeasuringInterval = productRules?.get('needsMeasuringInterval');
    const showTransmissionInterval = productRules?.get('needsTransmissionInterval');
    const showTimeOfFirstMeasurement = productRules?.get('needsTimeOfFirstMeasurement');
    const isEndress = manufacturer?.name === 'Endress+Hauser';

    const specifications = {};

    if (showTransmissionInterval) {
      specifications[[SpecificationKeys.ConnectivitySettings.TRANSMISSION_INTERVAL]] = {
        value: values.transmissionInterval.id,
      };
    }

    if (showMeasuringInterval) {
      specifications[[SpecificationKeys.ConnectivitySettings.MEASURING_INTERVAL]] = {
        value: values.measuringInterval.id,
      };
    }

    if (showTimeOfFirstMeasurement && isSomething(values.firstMeasurement)) {
      specifications[[SpecificationKeys.ConnectivitySettings.FIRST_MEASUREMENT]] = {
        value: values.firstMeasurement.toISOString(),
      };
    }

    const basicSettings = showMeasuringInterval && productRules.get('needsGPSInterval') && isSomething(values.transmissionInterval);
    const enableGPSSettings = productRules.get('enableGPSForOrderCode', basicSettings, values.extendedOrderCode, false);

    if (enableGPSSettings) {
      const gpsSpecifications = gpsUtils.setGPSInterval(values);
      specifications[[SpecificationKeys.GPSSettings.GPS_SEND_INTERVAL]] = gpsSpecifications[[SpecificationKeys.GPSSettings.GPS_SEND_INTERVAL]];
      specifications[[SpecificationKeys.GPSSettings.GPS_SEND_LATEST_INTERVAL]] = gpsSpecifications[[SpecificationKeys.GPSSettings.GPS_SEND_LATEST_INTERVAL]];
    }

    if (productRules.get('needsActivationStatus')) {
      specifications[[SpecificationKeys.ActivationSettings.SHOULD_BE_ACTIVE]] = {
        value: 'true',
      };
      specifications[[SpecificationKeys.ActivationSettings.DEVICE_ACTIVE]] = {
        value: 'false',
      };
    }

    if (isEndress && values.orderCode) {
      const orderCodeSpecValue = `${values.product.code}-${values.orderCode}`;
      specifications[[SpecificationKeys.GeneralSettings.ORDER_CODE]] = {
        value: orderCodeSpecValue,
      };
    }

    values.specifications?.forEach((specification) => {
      if (specification.key !== '' && specification.key?.name) {
        specifications[convertLabelToSpecificationKey(specification.key.name)] = {
          value: specification.value,
          ui_visible: true,
        };
      }
    });

    return specifications;
  };

  const handleOnSubmit = async (values, actions) => {
    try {
      let { manufacturer, product, tenant } = values;
      const { proceedWithTag } = values;

      const productRules = ProductRules.find(manufacturer.name, product.code, manufacturer.tenantPublic);
      const needsInstrumentation = productRules?.get('needsInstrumentation');
      const setFirstStatusToFailure = productRules?.get('setFirstStatusToFailure');

      // create tenant if needed
      tenant = await createNewTenant(tenant, manufacturer);

      // create manufacturer if needed
      if (manufacturer.new) {
        try {
          manufacturer = await api.post('/companies', { name: manufacturer.name, tenant: { id: tenant.id } });
        } catch (error) {
          if (error instanceof BadRequestError
              && error.contains('taken')) {
            actions.setErrors({
              manufacturer: intl.formatMessage({ id: 'api.error.manufacturer.taken' }),
            });
            return;
          }
          throw error;
        }
      }

      // create product if needed
      product = await createNewProduct(product, manufacturer, tenant, values);

      let payload = {
        serial_number: values.serial_number,
        product: { id: product.id },
        description: values.description,
      };

      if (!manufacturer.tenantPublic) {
        payload.tenant = tenant;
      }

      // in case of connected asset set first status to failure
      if (setFirstStatusToFailure) {
        const ehTenantResponse = await api.get('/tenants', { name: 'Endress+Hauser', public: true });
        const statusResponse = await api.get('/asset/statuses', { tenant_id: ehTenantResponse.tenants[0].id, code: 'failure' });
        payload = { ...payload, status: { id: statusResponse.asset_statuses[0].id } };
      }

      if (isNotEmpty(values.production_date)) {
        payload.production_date = values.production_date;
      }

      const response = await api.post('/assets', payload);

      const specifications = setSpecifications(productRules, values, manufacturer);

      if (Object.keys(specifications).length > 0) {
        await api.patch(`/assets/${response.id}/specifications`, specifications);
      }

      if (productRules.get('connectedAsset')) {
        await backend.patch(`/connected_assets/${response.id}`);
      }

      if (match.params?.id) {
        await api.post(`/assets/${response.id}/nodes`, {
          nodes: [
            {
              id: match.params.id,
            },
          ],
        });
      }

      notifier.showSuccess(intl.formatMessage({ id: 'asset_create.success_notification' }));

      const url = getUrlToNavigate(proceedWithTag, needsInstrumentation, response);
      browser.navigateTo(url);
    } catch (error) {
      handleError(error, actions, values);
    } finally {
      actions.setSubmitting(false);
      await subscription.refresh();
    }
  };

  const showAssetScanButton = rules.application().get('showScanAssetButton');
  const assetScanButton = (showAssetScanButton) ? (
    <ActionBarButtons>
      <ActionButton
        defaultLabel={intl.formatMessage({ id: 'button.scan' })}
        icon="lcm-iot-icon-scan"
        target="/assets/create_scan"
      />
    </ActionBarButtons>
  ) : null;

  return (
    <Container>
      <Row>
        <Column>
          <BackButton />
        </Column>
      </Row>
      <Row>
        <Column>
          <ConnectedAssetSubscriptionClue initialValues={initialValues} formValues={formValues} setSubscriptionValid={setSubscriptionValid} quotaExceeded={quotaExceeded} />
        </Column>
      </Row>
      <Row>
        <Column>
          <ActionBar>
            <Heading title={intl.formatMessage({ id: 'asset_create.header' })} />
            {assetScanButton}
          </ActionBar>
        </Column>
      </Row>
      {askPermission
        ? (
          <AssetPermissionModal
            serialNumber={askPermissionSerialNumber}
            manufacturer={askPermissionManufacturer}
            onClose={() => { setAskPermission(false); }}
          />
        )
        : null}
      <Row>
        <Column lg="7">
          <AssetForm onSubmit={handleOnSubmit} initialValues={initialValues} onChange={setFormValues} canSubmit={subscriptionValid} />
        </Column>
      </Row>
    </Container>
  );
}

AssetCreate.propTypes = {
  intl: intlShape.isRequired,
  api: apiShape.isRequired,
  backend: backendShape.isRequired,
  notifier: notifierShape.isRequired,
  browser: browserShape.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      serialNumber: PropTypes.string,
    }),
  }),
  user: userShape,
  rules: rulesShape,
  subscription: subscriptionShape.isRequired,
};

export default injectIntl(withNotifier(withBrowser(withBackend(withApi(withUser(withSubscription(withRules(AssetCreate))))))));
