import React, { useState } from 'react';
import md5 from 'js-md5';
import { v4 as uuid } from 'uuid';
import {
  displayUserName,
  formatAddress,
  withApi,
  withIntl,
  withNotifier,
  withUser,
  apiShape,
  intlShape,
  notifierShape,
  userShape,
  ActionBar,
  ActionBarButtons,
  Container,
  Details,
  DetailsHeaderItem,
  DetailsPicture,
  EditActionButton,
  Heading,
  Loader,
  VatPrefixes,
} from 'lcm-iot-commons';
import DataExports from 'lcm-iot-commons/client/lib/components/DataExports/DataExports';
import UserPasswordEdit from './UserPasswordEdit';
import MultiFactorAuthenticationDetails from './MultiFactorAuthenticationDetails';
import DemoDataHandler from './DemoDataHandler';
import NewsletterHandler from './NewsletterHandler';
import LoginDetails from './LoginDetails';
import NotificationEmailsHandler from './NotificationEmailsHandler';
import { getTimeZone } from '../../utils/timeZones';

export function UserProfile({
  api, intl, user, notifier,
}) {
  const [userDetails, setUserDetails] = useState();
  const [userSignIns, setUserSignIns] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const loadUserInfos = async () => {
    try {
      const responseUser = await api.get('/users/current?include=userroles,demo_data');
      responseUser.avatar = `https://www.gravatar.com/avatar/${md5(responseUser.email)}?s=160&d=mm`;
      const address = {
        companyName: responseUser.address?.company_name,
        streetName: responseUser.address?.street,
        streetNumber: responseUser.address?.street_number,
        city: responseUser.address?.city,
        zipCode: responseUser.address?.zip_code,
        regionCode: responseUser.address?.region_code,
        countryName: responseUser.address?.country_name,
        countryCode: responseUser.address?.country_code,
        vatNumber: responseUser.address?.vat_number || '',
        billingEmail: responseUser.address?.email,
      };
      const phone = responseUser.address?.phone;
      setUserDetails({ ...responseUser, address, phone });
      const responseSignIns = await api.get(`/users/${responseUser.id}/sign_ins`, { per_page: 5 });
      setUserSignIns(responseSignIns.sign_ins);
    } catch (error) {
      notifier.showError(api.translateError(error));
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    loadUserInfos();
  }, []);

  const renderAddress = () => {
    let addressItem = (<li className="profile-item-line" key={uuid()}>-</li>);
    if (userDetails?.address) {
      const formattedAddress = formatAddress(userDetails.address);
      addressItem = formattedAddress.length > 0
        ? formatAddress(userDetails.address).map((line) => (<li className="profile-item-line" key={uuid()}>{line}</li>))
        : <li className="profile-item-line" key={uuid()}>-</li>;
    }
    return (
      <>
        <div className="details-item padding-remove">
          <div className="details-item-name">{intl.formatMessage({ id: 'user_profile.contact_details' })}</div>
        </div>
        <ul id="user-profile-address" className="profile-item">
          {addressItem}
        </ul>
      </>
    );
  };

  const renderPhone = (
    <>
      <div className="details-item padding-remove">
        <div className="details-item-name">{intl.formatMessage({ id: 'label.phone' })}</div>
      </div>
      <ul id="user-profile-phone" className="profile-item">
        <li id="phone-number" className="profile-item-line" key={uuid()}>{userDetails?.phone || '-'}</li>
      </ul>
    </>
  );

  const renderTimeZone = () => {
    const identifier = userDetails?.time_zone;
    const timeZone = getTimeZone(identifier).label;
    return (
      <>
        <div className="details-item padding-remove">
          <div className="details-item-name">{intl.formatMessage({ id: 'label.time_zone' })}</div>
        </div>
        <ul id="user-profile-time-zone" className="profile-item">
          <li id="time-zone" className="profile-item-line" key={uuid()}>{timeZone || '-'}</li>
        </ul>
      </>
    );
  };

  const renderBillingEmail = (
    <>
      <div className="details-item padding-remove">
        <div className="details-item-name">{intl.formatMessage({ id: 'label.billing_email' })}</div>
      </div>
      <ul id="user-profile-billing-email" className="profile-item">
        <li
          id="billing-email"
          className="profile-item-line"
          key={uuid()}
        >
          {userDetails?.address.billingEmail || '-'}
        </li>
      </ul>
    </>
  );

  const renderVat = (
    <>
      <div className="details-item padding-remove">
        <div className="details-item-name">{intl.formatMessage({ id: 'label.vat_number' })}</div>
      </div>
      <ul id="user-profile-vat" className="profile-item">
        <li id="phone-number" className="profile-item-line" key={uuid()}>
          {VatPrefixes.forCountryCode(userDetails?.address?.countryCode)}
          {userDetails?.address?.vatNumber || '-'}
        </li>
      </ul>
    </>
  );

  const renderUserProfile = (
    <>
      <Details>
        <DetailsPicture>
          <img src={userDetails?.avatar} alt={displayUserName(userDetails)} />
        </DetailsPicture>
        <DetailsHeaderItem
          id="user-profile-name"
          translationKey="label.name"
          value={displayUserName(userDetails, false, true)}
        />
        <DetailsHeaderItem id="user-profile-email" translationKey="label.email" value={userDetails?.email} />
      </Details>
      {renderAddress()}
      {renderPhone}
      {renderTimeZone()}
      {renderBillingEmail}
      {userDetails?.address?.vatNumber && renderVat}
    </>
  );

  const renderEmailHandler = (
    <>
      <ActionBar>
        <Heading id="user-profile-emails" level={2} title={intl.formatMessage({ id: 'user_profile.emails' })} />
      </ActionBar>
      <Details>
        <NotificationEmailsHandler />
        <NewsletterHandler />
      </Details>
    </>
  );

  const isSsoUser = userDetails?.sso_provider || false;

  const renderContent = isLoading ? null : (
    <>
      {renderUserProfile}
      {isSsoUser ? null : (
        <div id="password-edit-form">
          <UserPasswordEdit userDetails={userDetails} />
        </div>
      )}
      {isSsoUser ? null : (
        <div id="multi-factor-authentication-group">
          <MultiFactorAuthenticationDetails />
        </div>
      )}
      <DemoDataHandler />
      {renderEmailHandler}
      <LoginDetails signIns={userSignIns} ssoProvider={userDetails?.sso_provider} />
      <DataExports exportType="complete" user={user} />
    </>
  );

  return (
    <Container>
      <ActionBar>
        <Heading id="user-profile-header" title={intl.formatMessage({ id: 'user_profile.header' })} />
        <ActionBarButtons>
          <EditActionButton id="edit-user-profile-button" target="/profile/edit" />
        </ActionBarButtons>
      </ActionBar>
      {renderContent}
      <Loader id="user-profile-loader" loading={isLoading} />
    </Container>
  );
}

UserProfile.propTypes = {
  api: apiShape.isRequired,
  intl: intlShape.isRequired,
  notifier: notifierShape.isRequired,
  user: userShape.isRequired,
};

export default withUser(withApi(withNotifier(withIntl(UserProfile))));
