import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function Clickable({
  target, onClick, children, className, onAuxClick, outside = false,
}) {
  const handleOnClick = (event) => {
    event.preventDefault();
    onClick(event);
  };

  const handleOnAuxClick = (event) => {
    event.preventDefault();
    onAuxClick(event);
  };

  const linkProps = {
    ...(onClick && { onClick: handleOnClick }),
    ...(onAuxClick && { onAuxClick: handleOnAuxClick }),
    ...(target && { to: target }),
  };

  if (outside && target) {
    return (
      <div className={`clickable outside ${className}`}>
        <Link {...linkProps} />
        {children}
      </div>
    );
  }

  if (target) return (<Link className={`clickable ${className}`} {...linkProps}>{children}</Link>);

  if (onClick) return (<button className={`clickable ${className}`} type="button" onClick={onClick} onAuxClick={onAuxClick}>{children}</button>);

  return (<div className="clickable">{children}</div>);
}

Clickable.propTypes = {
  target: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  outside: PropTypes.bool,
  onAuxClick: PropTypes.func,
};

Clickable.defaultProps = {
  className: '',
  target: undefined,
  onClick: undefined,
  children: undefined,
};

export default Clickable;
